import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box, Typography, TextField, Button, CircularProgress } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import main, { formatCash, _DateTime, format_cash} from '../resources/code/utils'
import Fade from '@mui/material/Fade'
import { useLoaderData, useOutletContext, useNavigate } from "react-router-dom";

export function loader({params}){
    console.log(params)
    const parameters = params.parameters.split('&')
    
    const rules = {}
    for(const param of parameters){
        const s = param.split('=')
        if(s.length === 2){
            rules[s[0]] = s[1]
        }
    }

    console.log(rules)

    return rules
}  


export default function PayClientDebts() {
    const navigate = useNavigate();

    const rules = useLoaderData();
    const [debts, setDebts] = useState({ stoveDebt: 0, pelletPaymentDebt: 0, stovePaymentDebt: 0 });
    const [payments, setPayments] = useState({ stoveDebt: '', pelletPaymentDebt: '', stovePaymentDebt: '' });
    const [ setSnack, setAlert, username ] = useOutletContext()
    const [paymentDates, setPaymentDates] = useState({
        stoveDebt: new Date(),
        pelletPaymentDebt: new Date(),
        stovePaymentDebt: new Date(),
    });
    const [clientName, setClientName] = useState('');
    const [activeTab, setActiveTab] = useState(0); // Tab index

    const fetchDebts = async (clientID) => {
        const response = await fetch(`${main.server}/get_all_client_debts?client=${clientID}`);
        const result = await response.json();
        const { stove_debt, pellet_payment_debt, stove_payment_debt } = result.data;
        setDebts({ 
            stoveDebt: stove_debt, 
            pelletPaymentDebt: pellet_payment_debt, 
            stovePaymentDebt: stove_payment_debt 
        });
    };

    useEffect(() => {
        if (rules.clientID) {
            setClientName(rules.client_name);
            fetchDebts(rules.clientID);
        }
    }, [rules]);

    const handlePaymentChange = (type, value) => {
        console.log(value)
        if(value.search(',') !== -1){
            console.log('triggered')
            let clean = value.replaceAll(',','')
            
            if((!isNaN(clean) && Number(clean) <= debts[type]) || clean === '' || clean === '0' ){
                
                clean = Number(clean)
                setPayments((prev) => ({ ...prev, [type]: clean }));
            
                
            }
        }else{
            console.log('not triggered')
            if((!isNaN(value) && Number(value) <= debts[type]) || value === '' || value === '0'){
                let v = Number(value)
                console.log(v)
                setPayments((prev) => ({ ...prev, [type]: v }));
                
            } 
        } 



   
    };

    const handleDateChange = (type, date) => {
        setPaymentDates((prev) => ({ ...prev, [type]: date }));
    };

    const recordPayment = async (type) => {
        if (!payments[type]) {
            alert('Please enter a valid payment amount');
            return;
        }

        const payload = {
            client: rules.clientID,
            amount: payments[type],
            date: paymentDates[type].toDateString(),
            type,
            username,
        };

        const form = new FormData()
        form.append('form', JSON.stringify(payload))

        const response = await fetch(`${main.server}/pay_client_debt`, {
            method: 'POST',
            body: form
        });

        const result = await response.json();
        if (result.request_status) {
            setAlert({
                message: result.message,
                type: 'success'
            })
            setTimeout(() => {
                navigate(`/clients/view_client/${rules.clientID}`)
            }, 3000)
     
        } else {
            setAlert({
                message: result.message,
                type: 'error'
            })
        }
    };

    const tabs = [
        { key: 'stoveDebt', label: 'Stove Debt' },
        { key: 'pelletPaymentDebt', label: 'Pellet Payment Debt' },
        { key: 'stovePaymentDebt', label: 'Stove Payment Debt' },
    ];

    return (
        <Fade in={true}>
        <div className="page" >

        <Box sx={{ maxWidth: 800, margin: '0 auto', padding: 3 }}>
            {/* Client Info */}
            <Box sx={{ marginBottom: 3, textAlign: 'center' }}>
                <Typography variant="h4" gutterBottom>
                    Pay Client Debts
                </Typography>
                <Typography variant="h6" gutterBottom>
                    Client: {clientName}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-around', padding: 2, backgroundColor: '#f9f9f9', borderRadius: 2, boxShadow: 1 }}>
                    {Object.entries(debts).map(([key, value]) => (
                        <Typography key={key} variant="body1">
                            {key.replace(/([A-Z])/g, ' $1')}: {format_cash(value)}
                        </Typography>
                    ))}
                </Box>
            </Box>

            {/* Tabs */}
            <Tabs
                value={activeTab}
                onChange={(e, newValue) => setActiveTab(newValue)}
                indicatorColor="primary"
                textColor="primary"
                centered
                sx={{ marginBottom: 3 }}
            >
                {tabs.map((tab, index) => (
                    <Tab key={tab.key} label={tab.label} />
                ))}
            </Tabs>

            {/* Tab Content */}
            {tabs.map((tab, index) => (
                <Box
                    key={tab.key}
                    role="tabpanel"
                    hidden={activeTab !== index}
                    sx={{ display: activeTab === index ? 'block' : 'none' }}
                >
                    <Typography variant="h5" gutterBottom>
                        {tab.label}
                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: 2 }}>
                        Total Debt: {format_cash(debts[tab.key])}
                    </Typography>
                    <Box sx={{ marginBottom: 2 }}>
                        <CircularProgress
                            variant="determinate"
                            value={(payments[tab.key] / debts[tab.key]) * 100 || 0}
                            size={100}
                            sx={{ color: '#3f51b5' }}
                        />
                        <Typography variant="caption" sx={{ display: 'block', marginTop: 1 }}>
                            Remaining: {format_cash(debts[tab.key] - payments[tab.key])}
                        </Typography>
                    </Box>
                    <LocalizationProvider dateAdapter={AdapterDateFns} >
                        <DatePicker
                            label="Payment Date"
                            value={paymentDates[tab.key]}
                            onChange={(date) => handleDateChange(tab.key, date)}
                            renderInput={(params) => <TextField {...params} fullWidth sx={{ marginBottom: 2 }} />}
                        />
                    </LocalizationProvider>
                    <TextField
                        label="Payment Amount"
                        type="text"
                        value={format_cash(payments[tab.key]) || ''}
                        onChange={(e) => handlePaymentChange(tab.key, e.target.value)}
                        fullWidth
                        sx={{ marginBottom: 2, marginTop: 2 }}
                    />
                    <Button
                        onClick={() => recordPayment(tab.key)}
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ marginTop: 10 }}
                    >
                        Process Payment
                    </Button>
                </Box>
            ))}
        </Box>
        </div>
        </Fade>
    );
}
