
export const phone_number = 'phone_number'
export const text = 'text'
export const number = 'number'


const main = {
    server: 'http://151.236.218.188:9500',
    //server:'http://127.0.0.1:8000',
    id_types: ['NIDA', 'Drivers LIcence', 'PASSPORT', 'ELECTON CARD']
}



export function zeroPadding(num, len){
    num = (Array(len + 1).join("0") + num).slice(-len)
    
    return num
}
export function _DateTime(dt){

    if(dt !== ''){
        dt = new Date(dt)
        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
        const Years = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        return `${days[dt.getDay()]} ${Years[dt.getMonth()]} ${dt.getDate()} ${dt.getFullYear()} ${zeroPadding(dt.getHours(), 2) }:${zeroPadding(dt.getMinutes(), 2)}`
    }else{
        return '-'
    }
}
export function _Date(dt){
    if(dt !== ''){
        dt = new Date(dt)
        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
        const Years = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        return `${days[dt.getDay()]} ${Years[dt.getMonth()]} ${dt.getDate()} ${dt.getFullYear()}`
        
    }else{
        return '-'
    }
}
export function Refresh(time=3000){
    setTimeout(() => { window.location.reload() }, time)
}

export function formatCash(cash){
    function splitter(cash){
     const section = cash.slice(-3)
     if(section.length < 3){
         return section
     }
     cash = cash.slice(0, cash.length - 3)
     const n = splitter(cash)
     return ((n)? n + ',' +  section : section)
 
    }
    let x = splitter(String(cash))
    if(cash === ''){
        x = 0
    }

    return x + '/='
}

export function format_cash(cash){
    function splitter(cash){
     const section = cash.slice(-3)
     if(section.length < 3){
         return section
     }
     cash = cash.slice(0, cash.length - 3)
     const n = splitter(cash)
     return ((n)? n + ',' +  section : section)
 
    }
    return splitter(String(cash)) 
}


export function uuid4() {
    const uuid = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16))
    return uuid.slice(0,8)
}

const levels = ['b', 'kb', 'mb', 'gb']
    
export function get_file_size(size, level=0){
    if(size >= 1024){
        size = size / 1024
        level += 1
    }else if (size < 1024){
        return `${String(size).slice(0, 6)} ${levels[level]}`
    }
    return get_file_size(size, level)
}

export function check_phone_number(number){
    if(number === ''){
        return true
    }
    if(number[0] === '0'){
        if(number.length !== 10){
            return false
        }
        return true
    }
    return false
}


export function Update(elm, updater, type){
    let value = elm.target.value
    
    if(type === phone_number){
        if((Number(value) || value === '' || value === '0') && value.length <= 10){
          updater(value)
        }    
    }
    else if(type === number){
      if(value.search(',') !== -1){
       
        let clean = value.replaceAll(',','')
        
       
        if(Number(clean) || clean === '' || clean === '0'){
            clean = Number(clean)
            updater(Number(clean))
        }
      }else{
        if(Number(value) || value === '' || value === '0'){
            value = Number(value)
            updater(Number(value))
        } 
      } 
    }else{
        updater(value)
    }
}

export default main


